import React from "react"
import { AtSymbolIcon } from '@heroicons/react/outline'
export default function Example() {
    return (
      <div className="relative py-16 bg-gray-50 overflow-hidden">
        
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Mentions légales
              </span>
            </h1>
          </div>
          <div className="mt-6 prose prose-orange prose-lg text-gray-500 mx-auto text-justify">
            <p>
            Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l’ "Utilisateur", du site www.ceosavocats.com , ci-après le "Site", les présentes mentions légales.
            </p>
            <p>
            La connexion et la navigation sur le Site par l’Utilisateur implique l'acceptation intégrale et sans réserve des présentes mentions légales.
            </p>
            <p>
            Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».
            </p>
            <h2>
            ARTICLE 1 - L'ÉDITEUR
            </h2>
            <p>
            L’édition et la direction de la publication du Site est assurée par CEOS Avocats, domiciliée au 149 Bd de Stalingrad, 69100 Villeurbanne, dont le numéro de téléphone est le 04 86 80 10 47, et l'adresse e-mail contact<AtSymbolIcon  className="h-5 inline" />ceosavocats.com
 
 ci-après l' "Editeur".

            </p>
            <h2>
            ARTICLE 2 - CONCEPTION ET HÉBERGEUR
            </h2>
            <p>
            Le site a été conçu par la société Koudepouce SAS, dont le siège social est au 97 allée Alexandre Borodine 69800 Saint-Priest. Plus de détail sur <a href="http://www.koudepouce.fr" target="_blank" rel="noreferrer">www.koudepouce.fr</a>
            </p>
            <p>
            L'hébergeur du Site est la société Netlify, dont le siège social est situé au 610 22ND St Ste 315 San Francisco, CA, 94107-3163 United States, avec le numéro de téléphone :(925) 922-0921, <a href="http://www.netlify.com" target="_blank" rel="noreferrer">www.netlify.com</a>
            </p>
            <h2>
            ARTICLE 3 - ACCÈS AU SITE
            </h2>
            <p>
            Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.
            </p>
            <p>
            En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.
            </p>
            <h2>
            ARTICLE 4 - COLLECTE DES DONNÉES
            </h2>
            <p>
            Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. 
            </p>
            <p>
            En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :
<br />·         par mail à l'adresse email contact<AtSymbolIcon  className="h-5 inline" />ceosavocats.com

            </p>
            <p>
            Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l’Editeur est prohibée et pourra entraîner des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.

            </p>
            <p>
            Pour plus d’informations, se reporter aux CGU du site www.ceosavocats.com accessible en <a href="/legal/cgu/">cliquant ici</a>.
            </p>
            <p>
            A découvrir également : notre <a href="/legal/politique-confidentialite/">politique en matière de confidentialité</a>.
            </p>
          </div>
        </div>
      </div>
    )
  }
  